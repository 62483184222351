import React from 'react'

import './Home.css'
import { Link } from 'react-scroll'
function Home() {
  return (
    <section className="home">
    <div className="home-container">
        <div className="content">
            <h2>Welcome to 7togo</h2>
            <img src="./images/logo1.png" alt="" width={130} height={130}/>
            <div className="btns"> 
                <button><Link to="products">Check menu</Link></button>
                
            </div>
        </div>
        
     
    </div>
</section>
  )
}

export default Home
