import React, { useRef, useState, useEffect } from 'react';
import { IoIosArrowForward,IoIosArrowBack } from "react-icons/io";
import data from '../../data/data.json';
import './Menu.css';

function Menu({filter,setFilter}) {
  
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

 
  
  const wrapperRef = useRef(null);
  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      carousel.classList.remove("dragging");
    };

    const handleInfiniteScroll = () => {
      const scrollPercentage = (carousel.scrollLeft / (carousel.scrollWidth - carousel.offsetWidth)) * 100;

      setIsAtBeginning(scrollPercentage === 0);
      setIsAtEnd(scrollPercentage === 100);
    };

    if (carousel) {
      carousel.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      carousel.addEventListener("scroll", handleInfiniteScroll);
    }

    // Clear event listeners on component unmount
    return () => {
      if (carousel) {
        carousel.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        carousel.removeEventListener("scroll", handleInfiniteScroll);
      }
    };
  }, [isDragging, startX, startScrollLeft, isAutoPlay]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    carouselRef.current.classList.add("dragging");
    setStartX(e.pageX);
    setStartScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleWrapperMouseEnter = () => {
    clearTimeout(timeoutIdRef.current);
  };

  const handleLeftArrowClick = () => {
    const itemWidth = carouselRef.current.clientWidth;
    carouselRef.current.scrollLeft -= itemWidth;
  };

  const handleRightArrowClick = () => {
    const itemWidth = carouselRef.current.clientWidth;
    carouselRef.current.scrollLeft += itemWidth;
  };

  return (
    <div>
      <section className="products section" id="products">
        <div className="products-container container">
          
          <div
            className="wrapper"
            ref={wrapperRef}
            onMouseEnter={handleWrapperMouseEnter}
          >
            <IoIosArrowBack className='i' onClick={handleLeftArrowClick} style={{ opacity: isAtBeginning ? 0.5 : 1 }} />
            <ul className="carousel" ref={carouselRef}>
              {data.CategoryData.map(category => (
                <li
                  key={category.id}
                  className={`card${filter === category.name && 'active-product'}`}
                  onClick={() => setFilter(category.name)}
                  data-filter={`.${category.name.toLowerCase()}`}
                >
                  <h2 className={`categorybutton  ${filter === category.name && 'active'}`} >{category.name}</h2>
                </li>
              ))}
            </ul>
            <IoIosArrowForward className='i' onClick={handleRightArrowClick} style={{ opacity: isAtEnd ? 0.5 : 1 }} />
          </div>
          <div className="products-content grid">
            {data.categories.map((categorie, index) => {
              return (
                categorie.category === filter && (
                  <article className="products-card main-menu" key={index}>
                    <div className="products-shape">
                      <img src={categorie.imageUrl} alt="" className="products-img" />
                    </div>
                    <div className="products-data">
                      <h2 className="products-price">{categorie.price}dh</h2>
                      <h3 className="products-name">{categorie.title}</h3>
                    </div>
                  </article>
                )
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Menu;
