import React from 'react'
import { Link } from 'react-scroll';

import './Special.css'

function Special({setFilter}) {
  return (
    <div className="special section container" id="special">
        <div className="special-container ">
          
      </div>

          <div className="special-category">
           <Link to='products'>
            <div className="item" onClick={()=>setFilter("petit dejeuner")}>
              <div className="image">
                <div className="breakfast"></div>  
              </div>
            
              <div className="special-info">
                
                <div className='item-info' >
                  <h3 className="special-title">BREAKFAST</h3>
                  <img src="./images/breakfasticon.png" alt="breakfasticon" />
                </div>
                
              </div>
            </div>
</Link>
<Link to='products'>
            <div className="item" onClick={()=>setFilter("burger")}>
            <div className="image">
            <div className="breakfast"></div> 
            </div>

            <div className="special-info">
          <div className='item-info'>
          <h3 className="special-title">LUNCH</h3>
          <img src="./images/lunchicon.png" alt="breakfasticon" />
          
          </div>
        </div>
            </div>

</Link>
<Link to='products'>
            <div className="item" onClick={()=>setFilter("crepes")}>

            <div className="image">
            <div className="breakfast"></div> 
            </div>
            <div className="special-info">
          <div className='item-info'>
          <h3 className="special-title">Dessert</h3>
          <img src="./images/desserticon.png" alt="breakfasticon" />
          
          </div>
        </div>
           </div>
            </Link>
             
          </div>
        </div>
      
  )
}

export default Special
