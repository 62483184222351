import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Special from './components/Special/Special';
import Menu from './components/Menu/Menu';
import Place from './components/Place/Place';
import Contact from './components/Contact/Contact';
import './App.css';
import { useState } from 'react';

function App() {
  const [filter, setFilter] = useState('burger');
  return (
    <div className="App">
      <Header />
      <main className='main'>
        <Home />
        <Special setFilter={setFilter}/>
        <Menu filter={filter} setFilter={setFilter}/>
        <Place/>
        <Contact/>
      <div className="copy">
        <span></span>
      <p>&copy;reserved by Seven Pixel network</p>
      </div>
        </main>
    </div>
  );
}

export default App;
