import React from 'react'
import { FaPhoneAlt, FaEnvelope,FaFacebookF,FaInstagram,FaWhatsapp ,FaTiktok } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import './Contact.css'



function Contact() {
  return (
    <section className='contact container' id='contact'>
      <div className="row">

			<div className="col left">
				
				<div className="contactTitle">
					<h2>7 to go</h2>
					
				</div>

				<div className="contactInfo">
					
					<div className="iconGroup">
						<div className="icon">
							<FaPhoneAlt className='iconn'/>
						</div>
						<div className="details">
							<span>Phone</span>
							<span>+212684-393369</span>
						</div>
					</div>

					<div className="iconGroup">
						<div className="icon">
							<FaEnvelope className='iconn'/>
						</div>
						<div className="details">
							<span>Email</span>
							<a href="mailto:seventogo1@gmail.com">seventogo1@gmail.com</a>
						</div>
					</div>

					<div className="iconGroup">
						<div className="icon">
							<FaLocationDot className='iconn'/>
						</div>
						<div className="details">
							<span>Location</span>
							<span>15 ANGLE IBN BATTOTA ET MUSTAPHA RAFII, KENITRA</span>
						</div>
					</div>

				</div>


				<div className="socialMedia">
				<a href="#"><FaFacebookF className='socialicon'/></a>
					<a href="https://www.instagram.com/seventogo7/"><FaInstagram className='socialicon'/></a>
					<a href="https://api.whatsapp.com/send?phone=+212684-393369&amp;text=Bonjour."><FaWhatsapp  className='socialicon'/></a>
					<a href="https://www.tiktok.com/@seventogo7"><FaTiktok className='socialicon'/></a>
				</div>

	
			</div>

		</div>
		
    </section>
  )
}

export default Contact

