import React, { useState } from 'react'
import {FaStream} from'react-icons/fa'
import { Link } from 'react-scroll';
import './Header.css'

function Header() {
  const[headercolor,setHeadercolor]=useState(false);
  const[showmenu,setShowmenu]=useState(false);
  const changedHeadercolor=()=>{
    if (window.scrollY>=50) {
      setHeadercolor(true);
    }
    else{
      setHeadercolor(false)
    }
  }
  window.addEventListener('scroll',changedHeadercolor);
  return (
    <header className={`header ${headercolor && 'scroll-header'}`} id="header">
      <nav className="nav container">
        <Link to="home" className="nav-logo"><img src="./images/logo1.png" alt="logo" /></Link>

        <div className={`nav-menu ${showmenu ? 'active' : ''}`} id="nav-menu">
          <ul className="nav-list">
            <li className="nav-item">
              <Link to="home"  
              
              className="nav-link active-link">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="products" className="nav-link">Menu</Link>
            </li>

            <li className="nav-item">
              <Link to="place" className="nav-link">Place</Link>
            </li>
            <li className="nav-item">
              <Link to="contact" className="nav-link">Contact us</Link>
            </li>
          </ul>

          
        </div>

        <div className="nav-toggle" id="nav-toggle" onClick={()=>{setShowmenu(!showmenu)}}>
          <FaStream/>
        </div>
      </nav>
    </header>

  )
}

export default Header
