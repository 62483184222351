import React, { useState } from 'react'
import data from '../../data/data.json'
import './Place.css'

function Place() {
    const [selectedimg,setSelectedimg]=useState(data.places[0].imageUrl)
  return (
    <section className="place section" id="place">
        <div className="place-container container">
          <h2 className="place-bigtitle">
            Make you feels good With our<br/> comfy fasilities
          </h2>

          <div className="place-content ">
            <div className="place-images">
              <img
                src={selectedimg}
                alt=""
                className="place-img-big"
              />
              <div className="place-img-smalls">
                {data.places.map((place,index)=>{
                    return(
                    <img
                    key={index}
                    src={place.imageUrl}
                    onClick={()=>setSelectedimg(place.imageUrl)}
                    alt=""
                    className={`place-img-small ${selectedimg === place.imageUrl && 'active'}`}
                  />
                    )
                })}
              </div>
            </div>

            <iframe className="iframe" title='SevenToGo' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3297.4974920560103!2d-6.594978824274548!3d34.26136397307902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDE1JzQwLjkiTiA2wrAzNSczMi43Ilc!5e0!3m2!1sfr!2sma!4v1706958935704!5m2!1sfr!2sma" width="800" height="600" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="place-data">
              <h2 className="place-title">Find a place you love</h2>
              <p className="place-description">
                We provide the best place to stay productive 
                and focus with good vibes and beautiful design.
              </p>
            </div>
        </div>
      </section>
  )
}

export default Place
